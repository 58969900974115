$fleet-green: #015959;

.fleet_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: url("../../images/fleet_module_BG.svg");
  background-size: cover;
  background-repeat: repeat-x;
}

// .fleet_form_container {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-left: 15vw;
//   margin-bottom: 6rem;
//   @include breakpoint(large) {
//     margin: 0 auto;
//   }
// }

.fleet_form_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; 
  margin-bottom: 6rem;
  margin-left: 15vw;
  width: 30%; 
}


.fleet_logo {
  // width: 70px;
  // height: 70px;
  // margin: 0 auto;
  margin-bottom: 1.5rem;
  min-width: 70px;
  max-width: 400px;
  @include breakpoint(large) {
    margin: auto;
    max-width: 400px;
  }
}

.fleet_main_img_module {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @include breakpoint(large) {
    display: none;
  }
  .fleet_main_img {
    position: relative;
    right: 10;
    bottom: 0;
    width: 95%;
    overflow: visible;
    margin-right: 0vw;
  }
}

.fleet_button_wrapper {
  width: 100%;
  height: 3.5rem;
  margin: 0.5rem 2rem;

  display: flex;
  justify-content: center;

  text-align: center;
  align-items: center;
  border-radius: 2em;

  background: $fleet-green;

  @include breakpoint(large) {
    height: 3.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  .fleet_button {
    width: 100%;
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: _palette(white);

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }

  .fleet_button_migrate {
    width: 100%;
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: _palette(white);
    background: none;
    border: none;

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }
  .fleet_button_migrate:hover {
    cursor: pointer;
  }
}

//Ticket car modules//
.fleet_modules_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 90px;
  background: url("../../images/fleet_module_BG.svg");
  background-size: cover;
  overflow: auto;
}

.fleet_faq {
  background: none;
}

.modules_container {
  min-width: 400px;
  @include breakpoint(medium) {
    min-width: 80%;
  }
}

.fleet_header {
  min-height: 4em;
  width: 100%;

  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2em;
  background: $fleet-green;

  .fleet_title {
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: _palette(white);
    text-align: center;

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }
}

.modules_list {
  width: 100%;
  height: 100%;

  .modules_item {
    padding: 0.5em 1em;
    border-bottom: 1px solid #162056;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .modules_item_link {
      color: #162056;
      font-weight: _font(light);
      text-align: left;
      flex-grow: 2;
      margin-right: 1em;
    }

    .modules_item_button {
      color: #162056;
      font-weight: _font(light);
      text-align: left;
      flex-grow: 2;
      margin-right: 1em;
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
    }

    .modules_item_check {
      width: 24px;
      height: 24px;
    }
  }
}

/* Library View */
/* Library View */

.library {
  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  @include breakpoint(xsmall) {
    margin-top: _size(header_height_xsmall);
    height: calc(100% - #{_size(header_height_xsmall)});
  }

  .library_content_ticket_car {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: url("../../images/tc_guest_library_BG.png");
    background-size: cover;
    overflow: scroll;

    .library_payload_wrapper {
      display: flex;
      // height: 50rem;
      width: 100%;
      justify-content: space-between;

    	.library_container {

    		min-width: 480px;
        height: 100%;
  	    display: flex;
  	    flex-flow: column nowrap;
  	    justify-content: flex-start;
  	    align-items: center;

        flex-grow: 2;
        margin-left: 12rem;
        margin-bottom: 12rem;
        margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});
        @include breakpoint(large) {
          margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
          margin-left: 0px;
        }

        @include breakpoint(medium) {
          min-width: 420px;
          margin-top: calc(
            (#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)}
          );
        }

        @include breakpoint(small) {
          min-width: calc(100% - 2rem);
          margin-top: calc(
            (#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)}
          );
        }

        @include breakpoint(xsmall) {
          margin-top: 1rem;
        }

        // border: 1px solid blue;
        .library_content_wrapper {
          width: 50rem;
          @include breakpoint(large) {
            width: inherit;
          }
          .library_img_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 380px;
          }
          .library_header_list_wrapper {
            margin: 0 auto;
            width: 80%;
            @include breakpoint(large) {
              width: 100%;
            }
            .library_header {
              margin: 0 auto;
              width: 100%;
              height: 3.5rem;
              min-height: 56px;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: #ffc72c;
              max-width: 500px;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 0.5rem;
              }

              .library_title {
                font-size: 1.5rem;
                font-weight: _font(regular);
                text-align: center;
                color: _palette(white);
              
                @include breakpoint(large) {
                  font-size: 1.25rem;
                }
              
                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
              
              }
            }

            .library_list {
              margin: 0 auto;
              margin-top: 2.5rem;
              width: 80%;
              height: 100%;
              @include breakpoint(large) {
                width: 100%;
              }

              .library_item {
                padding: 0.5em 1em;
                text-align: left;
                border-bottom: 1px solid #888888;
                // max-width: 500px;
                // // PLEASE FIX ME IN BREAKPOINTS

                .library_item_link {
                  color: #666666;
                  font-weight: _font(light);
                  padding: 0.5em 1em;
                }
              }
            }
          }
        }
      }

      .library_right_wrapper {
        width: 50%;
        height: 100%;
        @include breakpoint(large) {
          display: none;
        }

        .library_splash_wrapper {
          width: inherit;
          img {
            margin-bottom: -30px;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 35%;
          }
        }
      }
    }
  }

  .library_content_despensas {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: url("../../images/despensas_guest_library_BG.png");
    background-size: cover;
    overflow: scroll;

    .library_payload_wrapper {
      display: flex;
      // height: 50rem;
      width: 100%;
      justify-content: space-between;

      .library_container {
        min-width: 480px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        flex-grow: 2;
        margin-left: 12rem;
        margin-bottom: 12rem;
        margin-top: calc(
          (#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)}
        );
        @include breakpoint(large) {
          margin-top: calc(
            (#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)}
          );
          margin-left: 0px;
        }

        @include breakpoint(medium) {
          min-width: 420px;
          margin-top: calc(
            (#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)}
          );
        }

        @include breakpoint(small) {
          min-width: calc(100% - 2rem);
          margin-top: calc(
            (#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)}
          );
        }

        @include breakpoint(xsmall) {
          margin-top: 1rem;
        }

        // border: 1px solid blue;
        .library_content_wrapper {
          width: 50rem;
          @include breakpoint(large) {
            width: inherit;
          }
          .library_img_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 380px;
          }
          .library_header_list_wrapper {
            margin: 0 auto;
            width: 80%;
            @include breakpoint(large) {
              width: 100%;
            }
            .library_header {
              margin: 0 auto;
              width: 100%;
              height: 3.5rem;
              min-height: 56px;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: #d52b1e;
              max-width: 500px;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 0.5rem;
              }

              .library_title {
                font-size: 1.5rem;
                font-weight: _font(regular);
                text-align: center;
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }

            .library_list {
              margin: 0 auto;
              margin-top: 2.5rem;
              width: 80%;
              height: 100%;
              @include breakpoint(large) {
                width: 100%;
              }

              .library_item {
                padding: 0.5em 1em;
                text-align: left;
                border-bottom: 1px solid #e7918b;
                // max-width: 500px;
                // // PLEASE FIX ME IN BREAKPOINTS

                .library_item_link {
                  color: #666666;
                  font-weight: _font(light);
                  padding: 0.5em 1em;
                }
              }
            }
          }
        }
      }

      .library_right_wrapper {
        width: 50%;
        height: 100%;
        @include breakpoint(large) {
          display: none;
        }

        .library_splash_wrapper {
          width: inherit;
          img {
            margin-bottom: -30px;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 35%;
          }
        }
      }
    }
  }

  .library_content_empresarial {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: url("../../images/empresarial_guest_BG.png");
    background-size: cover;
    overflow: scroll;

    .library_payload_wrapper {
      display: flex;
      // height: 50rem;
      width: 100%;
      justify-content: space-between;

      .library_container {
        min-width: 480px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        flex-grow: 2;
        margin-left: 12rem;
        margin-bottom: 12rem;
        margin-top: calc(
          (#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)}
        );
        @include breakpoint(large) {
          margin-top: calc(
            (#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)}
          );
          margin-left: 0px;
        }

        @include breakpoint(medium) {
          min-width: 420px;
          margin-top: calc(
            (#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)}
          );
        }

        @include breakpoint(small) {
          min-width: calc(100% - 2rem);
          margin-top: calc(
            (#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)}
          );
        }

        @include breakpoint(xsmall) {
          margin-top: 1rem;
        }

        // border: 1px solid blue;
        .library_content_wrapper {
          width: 50rem;
          @include breakpoint(large) {
            width: inherit;
          }
          .library_img_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 380px;
          }
          .library_header_list_wrapper {
            margin: 0 auto;
            width: 80%;
            @include breakpoint(large) {
              width: 100%;
            }
            .library_header {
              margin: 0 auto;
              width: 100%;
              height: 3.5rem;
              min-height: 56px;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: #00859b;
              max-width: 500px;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 0.5rem;
              }

              .library_title {
                font-size: 1.5rem;
                font-weight: _font(regular);
                text-align: center;
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }

            .library_list {
              margin: 0 auto;
              margin-top: 2.5rem;
              width: 80%;
              height: 100%;
              @include breakpoint(large) {
                width: 100%;
              }

              .library_item {
                padding: 0.5em 1em;
                text-align: left;
                border-bottom: 1px solid #00859b;
                // max-width: 500px;
                // PLEASE FIX ME IN BREAKPOINTS

                .library_item_link {
                  text-align: left;
                  color: #666666;
                  font-weight: _font(light);
                  padding: 0.5em 1em;
                }
              }
            }
          }
        }
      }

      .library_right_wrapper {
        width: 50%;
        height: 100%;
        @include breakpoint(large) {
          display: none;
        }

        .library_splash_wrapper {
          width: inherit;
          img {
            margin-bottom: -30px;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 35%;
          }
        }
      }
    }
  }

  .library_content_fleet {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    //justify-content: center;
    margin-top: 8rem;
    align-items: center;

    z-index: 10;

    background: url("../../images/fleet_module_BG.svg");
    background-size: contain;
    overflow: scroll;

    .library_payload_wrapper {
      display: flex;
      // height: 50rem;
      width: 100%;
      justify-content: space-between;

      .library_container {
        min-width: 80%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        //align-items: center;

        flex-grow: 2;
        // margin-left: 12rem;
        margin-bottom: 12rem;
        // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

        @include breakpoint(large) {
          // margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
          margin-left: 0px;
        }

        @include breakpoint(medium) {
          min-width: 420px;
          // margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
        }

        @include breakpoint(small) {
          min-width: calc(100% - 2rem);
          // margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
        }

        @include breakpoint(xsmall) {
          // margin-top: 1rem;
        }

        // border: 1px solid blue;
        .library_content_wrapper {
          width: 50rem;
          @include breakpoint(large) {
            width: inherit;
          }
          .library_img_wrapper {
            margin: 0 auto;
            margin-bottom: 1.5rem;
            width: 17rem;
          }
          .library_header_list_wrapper {
            margin: 0 auto;
            width: 80%;
            @include breakpoint(large) {
              width: 100%;
            }
            .library_header {
              margin: 0 auto;
              width: 100%;
              height: 3.5rem;
              min-height: 56px;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: #015959;
              max-width: 500px;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 0.5rem;
              }

              .library_title {
                font-size: 1.5rem;
                font-weight: _font(regular);
                text-align: center;
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }

            .library_list {
              margin: 0 auto;
              margin-top: 2.5rem;
              width: 80%;
              height: 100%;
              @include breakpoint(large) {
                width: 100%;
              }

              .library_item {
                padding: 0.5em 1em;
                text-align: left;
                border-bottom: 1px solid #162056;
                // max-width: 500px;
                // // PLEASE FIX ME IN BREAKPOINTS

                .library_item_link {
                  color: #162056;
                  font-weight: _font(light);
                  padding: 0.5em 1em;
                }
              }
            }
          }
        }
      }

      .library_right_wrapper {
        width: 50%;
        height: 100%;
        display: none;
        @include breakpoint(large) {
          display: none;
        }

        .library_splash_wrapper {
          width: inherit;
          img {
            // margin-bottom: -30px;
            position: fixed;
            right: 0;
            bottom: 0;
            width: 50%;
          }
        }
      }
    }
  }

  .library_content_mantenimiento {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: url("../../images/mantenimiento_guest_BG.png");
    background-size: cover;
    overflow: scroll;

    .library_payload_wrapper {
      display: flex;
      // height: 50rem;
      width: 100%;
      justify-content: space-between;

      .library_container {
        min-width: 480px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        flex-grow: 2;
        margin-left: 12rem;
        margin-bottom: 12rem;
        margin-top: calc(
          (#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)}
        );
        @include breakpoint(large) {
          margin-top: calc(
            (#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)}
          );
          margin-left: 0px;
        }

        @include breakpoint(medium) {
          min-width: 420px;
          margin-top: calc(
            (#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)}
          );
        }

        @include breakpoint(small) {
          min-width: calc(100% - 2rem);
          margin-top: calc(
            (#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)}
          );
        }

        @include breakpoint(xsmall) {
          margin-top: 1rem;
        }

        // border: 1px solid blue;
        .library_content_wrapper {
          width: 50rem;
          @include breakpoint(large) {
            width: inherit;
          }
          .library_img_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 500px;
          }
          .library_header_list_wrapper {
            margin: 0 auto;
            width: 80%;
            @include breakpoint(large) {
              width: 100%;
            }
            .library_header {
              margin: 0 auto;
              width: 100%;
              height: 3.5rem;
              min-height: 56px;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: #00859b;
              max-width: 500px;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 0.5rem;
              }

              .library_title {
                font-size: 1.5rem;
                font-weight: _font(regular);
                text-align: center;
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }

            .library_list {
              margin: 0 auto;
              margin-top: 2.5rem;
              width: 80%;
              height: 100%;
              @include breakpoint(large) {
                width: 100%;
              }

              .library_item {
                padding: 0.5em 1em;
                text-align: left;
                border-bottom: 1px solid #00859b;
                // max-width: 500px;
                // PLEASE FIX ME IN BREAKPOINTS

                .library_item_link {
                  text-align: left;
                  color: #666666;
                  font-weight: _font(light);
                  padding: 0.5em 1em;
                }
              }
            }
          }
        }
      }

      .library_right_wrapper {
        width: 50%;
        height: 100%;
        @include breakpoint(large) {
          display: none;
        }

        .library_splash_wrapper {
          width: inherit;
          img {
            margin-bottom: -30px;
            position: absolute;
            right: 0;
            bottom: 265px;
            width: 35%;
          }
        }
      }
    }
  }
